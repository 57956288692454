import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Icon, Input, message, Select } from 'antd'

import Wishlist from '../../../components/Wishlist'
import CallToAction from '../../../components/CallToAction'
import raidbotsLogo from '../../../../../../assets/images/raidbots-logo.svg'
import qeLogo from '../../../../../../assets/images/qe-logo.png'

import WishlistService from '../../../services/WishlistService'
import { difficultyOptions, useSetting } from '../../../helpers'

const WishlistUploadPage = (props) => {
  const [reportId, setReportId] = useState('')
  const [clearExisting, setClearExisting] = useState(true)
  const [selectedCharacter, setSelectedCharacter] = useState()
  const [editing, setEditing] = useState(false)
  const [submittingReport, setSubmittingReport] = useState(false)
  const [submittingChanges, setSubmittingChanges] = useState(false)
  const [uploadedReport, setUploadedReport] = useState()
  const [changes, setChanges] = useState({})
  const enabledDifficulties = useSetting(props, 'wishlist_difficulties')
  const [difficulty, setDifficulty] = useState(
    enabledDifficulties.length
      ? Object.keys(difficultyOptions).indexOf(enabledDifficulties[0])
      : 0,
  )
  const [selectedInstance, setSelectedInstance] = useState(
    props.instances.find((i) => i.current) || props.instances[0],
  )
  const [configuration, setConfiguration] = useState(
    props.guild.selectedTeam.wishlist_configurations[0],
  )
  const [error, setError] = useState()
  const [editError, setEditError] = useState()
  const [valueDisplay, setValueDisplay] = useState(
    localStorage.getItem('wishlistValueDisplay') || 'percentage',
  )
  const wishlistLocked =
    useSetting(props, 'wishlist_locked') === true && props.user.accessLevel > 0
  const wishlistNotEditable = useSetting(props, 'wishlist_editable') === false

  const availableCharacters = (all = true) => {
    return props.guild.selectedTeam.characters.filter(
      (character) =>
        character.wishlist_visibility &&
        (props.user.character_data
          .map((ch) => ch.key)
          .includes(character.blizzard_key) ||
          (all && props.user.accessLevel === 0)),
    )
  }

  useEffect(() => {
    if (localStorage.getItem('wishlistCharacterId')) {
      setSelectedCharacter(
        availableCharacters().find(
          (ch) =>
            ch.id.toString() === localStorage.getItem('wishlistCharacterId'),
        ) ||
          availableCharacters(false)[0] ||
          availableCharacters()[0],
      )
    } else {
      setSelectedCharacter(
        availableCharacters(false)[0] || availableCharacters()[0],
      )
    }
    setConfiguration(props.guild.selectedTeam.wishlist_configurations[0])
  }, [props.guild.selectedTeam])

  useEffect(() => {
    if (selectedCharacter) {
      localStorage.setItem('wishlistCharacterId', selectedCharacter.id)
    }

    setEditing(false)
  }, [selectedCharacter])

  useEffect(() => {
    localStorage.setItem('wishlistValueDisplay', valueDisplay)
  }, [valueDisplay])

  useEffect(() => {
    if (submittingReport) {
      setError(undefined)
      setEditing(false)
      WishlistService.create(
        props.guild.id,
        selectedCharacter.id,
        reportId,
        configuration.id,
        clearExisting,
      )
        .then((result) => {
          setUploadedReport(result.data)
        })
        .catch((result) => {
          setError(
            result.response.data.error?.split(',')[0] ||
              'Something went wrong. Please try again.',
          )
        })
        .then(() => setSubmittingReport(false))
    }
  }, [submittingReport])

  useEffect(() => {
    if (submittingChanges) {
      setEditError(undefined)
      WishlistService.update(
        props.guild.id,
        selectedCharacter.id,
        changes,
        selectedInstance.id,
      )
        .then((result) => {
          message.success('Wishlist changes saved.', 5)
          setUploadedReport(result.data)
          setEditing(false)
        })
        .catch((result) => {
          setEditError(
            result.response.data.error?.split(',')[0] ||
              'Something went wrong. Please try again.',
          )
        })
        .then(() => {
          setSubmittingChanges(false)
        })
    }
  }, [submittingChanges])

  useEffect(() => {
    if (uploadedReport?.instance) {
      if (selectedInstance.id !== uploadedReport.instance.id)
        setSelectedInstance(uploadedReport.instance)

      setConfiguration(
        props.guild.selectedTeam.wishlist_configurations.find(
          (config) => config.id === uploadedReport?.configuration_id,
        ),
      )
    }
  }, [uploadedReport])

  useEffect(() => {
    if (!editing) setChanges({})
  }, [editing])

  const formatReportId = (reportId) => {
    const formatted = reportId
      .replace('raidbots.com/simbot/report/', '')
      .replace('https://', '')
      .replace('www.', '')
      .replace('.json', '')
      .replace('questionablyepic.com/api/upgrades/', '')
      .replace('questionablyepic.com/live/upgradereport/', '')

    return formatted
  }

  return (
    <div className='wishlists upload-page'>
      <header>
        <h1>
          <div className='title-caption'>Wishlists</div>
          <span>
            <Icon type='edit' />
            Edit wishlist
          </span>
        </h1>

        <div>
          {props.guild.selectedTeam.wishlist_configurations.length > 1 && (
            <Select
              placeholder='Fight style'
              className='fight-style'
              value={configuration?.id}
              optionLabelProp='label'
              onChange={(value) => {
                setConfiguration(
                  props.guild.selectedTeam.wishlist_configurations.find(
                    (config) => config.id === value,
                  ),
                )
              }}
            >
              {props.guild.selectedTeam.wishlist_configurations.map(
                (config) => (
                  <Select.Option
                    key={config.id}
                    value={config.id}
                    label={config.name}
                    className='fight-style-upload-option'
                  >
                    {config.name}
                    <span className='config-details'>
                      {config.description}
                      {`, ${
                        props.droptimizer_upgrade_levels[
                          ['mythic', 'heroic', 'normal', 'raid_finder'][
                            difficulty
                          ]
                        ].find(
                          (o) =>
                            o.value ===
                            config[
                              `upgrade_level_${
                                Object.keys(difficultyOptions)[difficulty]
                              }`
                            ],
                        )?.label || 'unknown'
                      } upgrades`}
                    </span>
                  </Select.Option>
                ),
              )}
            </Select>
          )}

          {props.instances.length > 1 && (
            <Select
              placeholder='Instance'
              className='instance'
              value={selectedInstance?.name}
              onChange={(value) =>
                setSelectedInstance(props.instances.find((i) => i.id === value))
              }
            >
              {props.instances.map((instance) => (
                <Select.Option key={instance.id} value={instance.id}>
                  {instance.name}
                </Select.Option>
              ))}
            </Select>
          )}

          {availableCharacters().length > 1 && (
            <Select
              placeholder='Character'
              className='character'
              value={selectedCharacter?.id}
              onChange={(value) =>
                setSelectedCharacter(
                  availableCharacters().find((ch) => ch.id === value),
                )
              }
            >
              {availableCharacters().map((character) => (
                <Select.Option key={character.id} value={character.id}>
                  {character.name}
                </Select.Option>
              ))}
            </Select>
          )}

          <Select
            placeholder='View'
            className='display-view'
            value={
              { absolute: 'Show value', percentage: 'Show %' }[valueDisplay]
            }
            onChange={(value) => setValueDisplay(value)}
          >
            <Select.Option value='percentage'>Show %</Select.Option>
            <Select.Option value='absolute'>Show value</Select.Option>
          </Select>
        </div>
      </header>

      <div className='upload-container'>
        <div className='left'>
          <h3>
            Upload{' '}
            <a
              href={`https://www.raidbots.com/simbot/droptimizer?region=${selectedCharacter?.region}&realm=${selectedCharacter?.realm_slug}&name=${selectedCharacter?.name}`}
              target='_blank'
              rel='noreferrer'
            >
              <img className='raidbots' title='Raidbots' src={raidbotsLogo} />
            </a>
            {true && (
              <span>
                or
                <a
                  href={`https://questionablyepic.com/live/UpgradeFinder`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img className='qe' title='QE Live' src={qeLogo} />
                </a>
              </span>
            )}{' '}
            report
          </h3>

          {uploadedReport?.instance ? (
            <div className='report-uploaded'>
              <Icon type='check-circle' />
              <span>
                Your report has been uploaded.{' '}
                <a
                  onClick={() => {
                    setUploadedReport(undefined)
                    setReportId('')
                  }}
                >
                  Upload another
                </a>
                .
              </span>
            </div>
          ) : (
            <React.Fragment>
              <p>
                Run the{' '}
                <a
                  className='droptimizer-link'
                  href={`https://www.raidbots.com/simbot/droptimizer?region=${selectedCharacter?.region}&realm=${selectedCharacter?.realm_slug}&name=${selectedCharacter?.name}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  Raidbots Droptimizer
                </a>{' '}
                or the{' '}
                <a
                  target='_blank'
                  rel='noreferrer'
                  className='droptimizer-link'
                  href='https://questionablyepic.com/live/UpgradeFinder'
                >
                  QE Live Upgrade Finder
                </a>{' '}
                for your character and paste the URL here.
              </p>

              <div className='input-container'>
                <Input.Group compact>
                  <Input
                    type='text'
                    disabled={
                      submittingReport ||
                      wishlistLocked ||
                      availableCharacters().length === 0
                    }
                    value={reportId}
                    label='Report ID'
                    autoComplete='off'
                    autoCorrect='off'
                    spellCheck='false'
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' && reportId) {
                        setSubmittingReport(true)
                      }
                    }}
                    onFocus={(event) => event.target.select()}
                    onClick={(event) => event.target.select()}
                    onChange={(event) =>
                      setReportId(formatReportId(event.target.value))
                    }
                  />
                  <Button
                    type='button'
                    onClick={() => setSubmittingReport(true)}
                    loading={submittingReport}
                    disabled={
                      !reportId ||
                      wishlistLocked ||
                      availableCharacters().length === 0
                    }
                  >
                    {!submittingReport && <span>Go</span>}
                  </Button>
                </Input.Group>
              </div>

              <div className='warnings'>
                {wishlistLocked ? (
                  <span className='error'>
                    All wishlists are currently locked for your team.
                  </span>
                ) : (
                  <span>
                    {configuration.description}
                    {`, ${
                      props.droptimizer_upgrade_levels[
                        ['mythic', 'heroic', 'normal', 'raid_finder'][
                          difficulty
                        ]
                      ].find(
                        (o) =>
                          o.value ===
                          configuration[
                            `upgrade_level_${
                              Object.keys(difficultyOptions)[difficulty]
                            }`
                          ],
                      )?.label || 'unknown'
                    } upgrades`}
                  </span>
                )}
                {error && <div className='error'>{error}</div>}
              </div>

              <div className='labels'>
                <Checkbox
                  checked={clearExisting}
                  onChange={() => setClearExisting(!clearExisting)}
                  disabled={
                    wishlistLocked || availableCharacters().length === 0
                  }
                >
                  Replace manual edits
                </Checkbox>
              </div>
            </React.Fragment>
          )}
        </div>

        <div className='divider' />

        <div className='right'>
          <h3>Edit manually</h3>
          <p>
            You can configure your entire wishlist manually, or adjust the
            result of the Droptimizer report. Manual entries will be shown
            differently in the overview.
          </p>
          {editing ? (
            <div className='button-bar'>
              <Button
                onClick={() => setEditing(false)}
                disabled={submittingChanges}
              >
                Cancel
              </Button>
              <Button
                type='primary'
                onClick={() => setSubmittingChanges(true)}
                loading={submittingChanges}
                disabled={submittingChanges}
              >
                Save changes
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => setEditing(true)}
              disabled={
                wishlistLocked ||
                wishlistNotEditable ||
                availableCharacters().length === 0
              }
            >
              Start editing
            </Button>
          )}
          <div className='warnings'>
            {editError && <span className='error'>{editError}</span>}
            {wishlistNotEditable && !wishlistLocked && (
              <span className='error'>
                This option has been disabled for your team.
              </span>
            )}
          </div>
        </div>
      </div>

      {availableCharacters().length === 0 && (
        <span className='notification'>
          You do not own any wishlist-enabled characters in this team&apos;s
          roster. You can try to{' '}
          {!props.user.loggedIn ? (
            <a
              href={`/auth/bnet?origin=${encodeURIComponent(
                `/${props.path.region}/${props.path.realm}/${props.path.guild}/${props.path.team}/wishlists/personal`,
              )}&region=${props.path.region}`}
            >
              log in
            </a>
          ) : (
            <React.Fragment>
              <a href='/logout'>log out</a> and in again to refresh your
              permissions
            </React.Fragment>
          )}{' '}
          or reach out on{' '}
          <a href='https://discord.gg/86eUAFz' target='_blank' rel='noreferrer'>
            {' '}
            Discord
          </a>
          .
        </span>
      )}

      <CallToAction
        title='RCLootCouncil integration'
        uid='rclootcouncil-addon'
        actions={[
          {
            label: 'Get on CurseForge',
            href: 'https://curseforge.com/wow/addons/rclootcouncil-wowaudit',
          },
          {
            label: 'Get on Wago',
            href: 'https://addons.wago.io/addons/rclootcouncil-wowaudit',
          },
        ]}
      >
        You can now view your wishlist data directly in-game with the wowaudit
        plugin for RCLootCouncil, in combination with the{' '}
        <a href='/desktop' target='_blank' rel='noreferrer'>
          desktop client
        </a>
        . Loot history will also be synchronised back.
      </CallToAction>

      {selectedCharacter && (
        <Wishlist
          character={selectedCharacter}
          instance={selectedInstance}
          editable={editing}
          changes={changes}
          setChanges={setChanges}
          uploadedReport={uploadedReport}
          configuration={configuration}
          valueDisplay={valueDisplay}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          props={props}
        />
      )}
    </div>
  )
}

export default WishlistUploadPage

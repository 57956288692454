import React, { useEffect, useState } from 'react'
import { Icon, Select, Spin } from 'antd'

import WishlistOverviewTable from '../../../components/WishlistOverviewTable'
import { difficultyOptions, useSetting } from '../../../helpers'
import TeamService from '../../../services/TeamService'
import CallToAction from '../../../components/CallToAction'

const WishlistOverviewPage = (props) => {
  const [data, setData] = useState()
  const enabledDifficulties = useSetting(props, 'wishlist_difficulties')
  const [difficulty, setDifficulty] = useState(
    enabledDifficulties.includes(
      localStorage.getItem('wishlistOverviewDefaultDifficulty'),
    )
      ? localStorage.getItem('wishlistOverviewDefaultDifficulty')
      : enabledDifficulties[0] || 'mythic',
  )
  const [selectedInstance, setSelectedInstance] = useState(
    props.instances.find((i) => i.current) || props.instances[0],
  )
  const [configuration, setConfiguration] = useState({ id: 'all' })
  const [valueDisplay, setValueDisplay] = useState(
    localStorage.getItem('wishlistValueDisplay') || 'absolute',
  )

  useEffect(() => {
    setData(undefined)
    TeamService.fetchLootWishlist(
      props.guild.id,
      props.guild.selectedTeam.id,
      difficulty,
      selectedInstance.id,
      configuration.id === 'all' ? null : configuration.id,
    ).then((result) => {
      setData(result.data)
    })
  }, [difficulty, selectedInstance, configuration, props.guild.selectedTeam])

  useEffect(() => {
    localStorage.setItem('wishlistValueDisplay', valueDisplay)
  }, [valueDisplay])

  useEffect(() => {
    localStorage.setItem('wishlistOverviewDefaultDifficulty', difficulty)
  }, [difficulty])

  return (
    <div className='wishlists overview'>
      <header>
        <h1>
          <div className='title-caption'>Wishlists</div>
          <span>
            <Icon type='profile' />
            Overview
          </span>
        </h1>

        <div>
          {props.guild.selectedTeam.wishlist_configurations.length > 1 && (
            <Select
              placeholder='Fight style'
              className='fight-style'
              value={configuration?.id}
              optionLabelProp='label'
              onChange={(value) => {
                setConfiguration(
                  props.guild.selectedTeam.wishlist_configurations.find(
                    (config) => config.id === value,
                  ) || { id: 'all' },
                )
              }}
            >
              <Select.Option key='all' value='all' label='All styles'>
                All styles
                <span className='config-details'>
                  Upgrades will show as a weighted average
                </span>
              </Select.Option>
              {props.guild.selectedTeam.wishlist_configurations.map(
                (config) => (
                  <Select.Option
                    key={config.id}
                    value={config.id}
                    label={config.name}
                  >
                    {config.name}
                  </Select.Option>
                ),
              )}
            </Select>
          )}

          {enabledDifficulties.length > 1 && (
            <Select
              placeholder='Difficulty'
              className='difficulty'
              value={difficulty}
              onChange={(value) => setDifficulty(value)}
            >
              {enabledDifficulties.map((diff) => (
                <Select.Option
                  key={diff}
                  value={diff}
                  label={difficultyOptions[diff]}
                >
                  {difficultyOptions[diff]}
                </Select.Option>
              ))}
            </Select>
          )}

          {props.instances.length > 1 && (
            <Select
              placeholder='Instance'
              className='instance'
              value={selectedInstance?.name}
              onChange={(value) =>
                setSelectedInstance(props.instances.find((i) => i.id === value))
              }
            >
              {props.instances.map((instance) => (
                <Select.Option key={instance.id} value={instance.id}>
                  {instance.name}
                </Select.Option>
              ))}
            </Select>
          )}

          <Select
            placeholder='View'
            className='display-view'
            value={
              { absolute: 'Show value', percentage: 'Show %' }[valueDisplay]
            }
            onChange={(value) => setValueDisplay(value)}
          >
            <Select.Option value='percentage'>Show %</Select.Option>
            <Select.Option value='absolute'>Show value</Select.Option>
          </Select>
        </div>
      </header>

      <CallToAction
        title='RCLootCouncil integration'
        uid='rclootcouncil-addon'
        actions={[
          {
            label: 'Get on CurseForge',
            href: 'https://curseforge.com/wow/addons/rclootcouncil-wowaudit',
          },
          {
            label: 'Get on Wago',
            href: 'https://addons.wago.io/addons/rclootcouncil-wowaudit',
          },
        ]}
      >
        You can now view your wishlist data directly in-game with the wowaudit
        plugin for RCLootCouncil, in combination with the{' '}
        <a href='/desktop' target='_blank' rel='noreferrer'>
          desktop client
        </a>
        . Loot history will also be synchronised back.
      </CallToAction>

      {data ? (
        <WishlistOverviewTable
          data={data}
          difficulty={difficulty}
          characters={props.guild.selectedTeam.characters.filter(
            (character) => character.wishlist_visibility,
          )}
          valueDisplay={valueDisplay}
        />
      ) : (
        <Spin
          id='wishlist-loading'
          spinning={true}
          size='large'
          tip='Loading ...'
        />
      )}
    </div>
  )
}

export default WishlistOverviewPage

import React, { useState, useEffect } from 'react'
import { Alert, Select, Icon, DatePicker } from 'antd'
const { RangePicker } = DatePicker

import LootHistoryTable from '../../../components/LootHistoryTable'
import LootStatisticsTable from '../../../components/LootStatisticsTable'
import TeamService from '../../../services/TeamService'

const LootHistoryPage = (props) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [season, setSeason] = useState(props.seasons.find((s) => s.current))
  const [customRange, setCustomRange] = useState()
  const [difficulty, setDifficulty] = useState('all')
  const [slot, setSlot] = useState('all')

  useEffect(() => {
    setLoading(true)
    refreshData()
  }, [season, customRange, props.guild.selectedTeam, difficulty, slot])

  const refreshData = async () => {
    TeamService.fetchLootHistory(
      props.guild.id,
      props.guild.selectedTeam.id,
      difficulty === 'all' ? undefined : difficulty,
      slot === 'all' ? undefined : slot,
      season?.id,
      customRange,
    ).then((result) => {
      setLoading(false)
      setData(result.data)
    })
  }

  const slots = [
    'Back',
    'Chest',
    'Feet',
    'Finger',
    'Hands',
    'Head',
    'Legs',
    'Neck',
    'Off_hand',
    'One_hand',
    'Ranged',
    'Shoulder',
    'Tier',
    'Trinket',
    'Two_hand',
    'Waist',
    'Wrists',
  ]

  return (
    <div className='wishlists loot-history'>
      <header>
        <h1>
          <div className='title-caption'>Wishlists</div>
          <span>
            <Icon type='history' />
            Loot History
          </span>
        </h1>
      </header>

      <div className='reading-width'>
        <p>
          This page displays all awarded items found in the loot history of the
          RCLootCouncil addon. If you want to update the data, install and run
          the{' '}
          <a href='/desktop' target='_blank' rel='noreferrer'>
            desktop client
          </a>
          .
        </p>
      </div>

      <div className='filter-bar'>
        <div className='field static'>
          <label>Difficulty</label>
          <Select
            placeholder='Difficulty'
            value={difficulty}
            optionFilterProp='children'
            onChange={setDifficulty}
          >
            <Select.Option key='all' value='all'>
              All
            </Select.Option>
            {['mythic', 'heroic', 'normal', 'raid_finder'].map(
              (difficulty, index) => (
                <Select.Option key={`diff-${index}`} value={difficulty}>
                  {difficulty.replace('_', ' ')}
                </Select.Option>
              ),
            )}
          </Select>
        </div>

        <div className='field static'>
          <label>Slot</label>
          <Select
            placeholder='Slot'
            value={slot}
            optionFilterProp='children'
            onChange={setSlot}
          >
            <Select.Option key='all' value='all'>
              All
            </Select.Option>
            {slots.map((slot, index) => (
              <Select.Option key={`diff-${index}`} value={slot.toLowerCase()}>
                {slot.replace('_', ' ')}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className='field grow'>
          <label>Season</label>
          <Select
            placeholder='Season'
            value={season?.id}
            optionFilterProp='children'
            onChange={(value) => {
              setSeason(props.seasons.find((s) => s.id === value))
              setCustomRange(undefined)
            }}
          >
            {props.seasons.map((season, _) => (
              <Select.Option key={`season-${season.id}`} value={season.id}>
                {season.full_name}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className='field'>
          <label>Custom range</label>
          <RangePicker
            value={customRange}
            allowClear={true}
            onChange={(value) => {
              if (value.length > 0) {
                setCustomRange(value)
                setSeason(undefined)
              } else {
                setCustomRange(undefined)
                setSeason(props.seasons.find((s) => s.current))
              }
            }}
          />
        </div>
      </div>

      {loading || data?.history_items.length > 0 ? (
        <React.Fragment>
          <h2 className='statistics'>
            <span>Statistics</span>
          </h2>
          <LootStatisticsTable
            loading={loading}
            refreshData={refreshData}
            items={data?.history_items || []}
            responseTypes={data?.loot_history_response_types || []}
            responseTypeOrder={data?.loot_history_response_type_order || []}
            characters={props.guild.selectedTeam.characters.filter(
              (character) => character.wishlist_visibility,
            )}
            {...props}
          />

          <h2>Items</h2>
          <LootHistoryTable
            loading={loading}
            refreshData={refreshData}
            items={data?.history_items || []}
            characters={props.guild.selectedTeam.characters.filter(
              (character) => character.wishlist_visibility,
            )}
            {...props}
          />
        </React.Fragment>
      ) : (
        <Alert
          type='warning'
          showIcon
          icon={<Icon type='history' />}
          message={
            <span>
              No loot history data found. Make sure you have the{' '}
              <a href='/desktop' target='_blank' rel='noreferrer'>
                wowaudit desktop client
              </a>{' '}
              installed and running.
            </span>
          }
        />
      )}
    </div>
  )
}

export default LootHistoryPage

import React, { useState, useMemo, useEffect } from 'react'
import { orderBy, flatten, keyBy } from 'lodash'
import { Button, Icon, Modal } from 'antd'
import moment from 'moment'

import Image from './Image'
import WishlistOverviewWish from './WishlistOverviewWish'

const WishlistOverviewTable = ({
  data,
  difficulty,
  characters,
  valueDisplay,
}) => {
  const [charactersById, setCharactersById] = useState(keyBy(characters, 'id'))
  const [activeEncounter, setActiveEncounter] = useState(data.encounters[0])
  const [viewingAge, setViewingAge] = useState(false)
  const [showTrackUpgrades, setShowTrackUpgrades] = useState(
    !localStorage.getItem('hidingTrackUpgrades'),
  )
  const difficulties = ['mythic', 'heroic', 'normal', 'raid_finder']
  const difficultyIndex = difficulties.indexOf(difficulty)

  useEffect(() => {
    setCharactersById(keyBy(characters, 'id'))
  }, [characters])

  const toggleTrackUpgrades = () => {
    if (showTrackUpgrades) {
      localStorage.setItem('hidingTrackUpgrades', 'true')
      setShowTrackUpgrades(false)
    } else {
      localStorage.removeItem('hidingTrackUpgrades')
      setShowTrackUpgrades(true)
    }
  }

  const teamHistory = useMemo(() => {
    return (
      <div className='wishlist-age-modal'>
        {data.metadata.map((character, i) => (
          <div key={i} className='character-specs'>
            <span>
              <Image name={`${character.class.replace(' ', '')}-2`} />
              {character.name}
            </span>
            {character.specs.map((spec, i) => {
              const difference = moment().diff(moment(spec.age), 'days')

              return (
                <div key={i} className='spec-age'>
                  <figure>
                    <img
                      alt={spec.name}
                      title={spec.name}
                      src={`https://render.worldofwarcraft.com/us/icons/36/${spec.icon}.jpg`}
                    />
                  </figure>
                  {React.createElement(
                    spec.report_url ? `a` : `span`,
                    {
                      className: spec.age
                        ? difference > 21
                          ? 'month'
                          : difference > 7
                          ? 'week'
                          : 'day'
                        : 'never',
                      href: spec.report_url,
                      target: '_blank',
                      rel: 'noreferrer',
                    },
                    spec.age ? moment(spec.age).fromNow() : 'never',
                  )}
                </div>
              )
            })}
          </div>
        ))}
      </div>
    )
  }, [data])

  const baseWowheadUrl = `https://wowhead.com/`

  return (
    <div className='overview-table'>
      <div className='aside'>
        <ul>
          {data.encounters.map((encounter) => (
            <li
              key={encounter.name}
              className={`encounter ${
                activeEncounter === encounter ? 'active' : ''
              }`}
              onClick={() => setActiveEncounter(encounter)}
            >
              <Image name={encounter.background} extension='jpg' />
              <div>
                <h3>{encounter.name}</h3>
                <span>
                  {encounter[`total_${valueDisplay}_value`] > 0 ? (
                    <span className='value'>
                      {valueDisplay === 'percentage'
                        ? `${encounter[`total_${valueDisplay}_value`]?.toFixed(
                            2,
                          )}%`
                        : encounter[`total_${valueDisplay}_value`]?.toFixed(0)}
                    </span>
                  ) : encounter.highest_manual_upgrade ? (
                    <span
                      className={`priority ${encounter.highest_manual_upgrade.toLowerCase()}`}
                    />
                  ) : (
                    'nothing'
                  )}
                </span>
              </div>
            </li>
          ))}
          <li
            className={`encounter all-items ${
              activeEncounter === 'all' ? 'active' : ''
            }`}
            onClick={() => setActiveEncounter('all')}
          >
            <div>
              <h3>All items</h3>
            </div>
          </li>
        </ul>
      </div>
      <section>
        <div className='header'>
          <h2>
            {activeEncounter === 'all' ? 'All items' : activeEncounter.name}
          </h2>
          <Button
            type='ghost'
            size='small'
            className='show-track-upgrades'
            onClick={toggleTrackUpgrades}
          >
            {showTrackUpgrades ? 'Hide' : 'Show'} track upgrades
          </Button>
          <Icon type='clock-circle' onClick={() => setViewingAge(true)} />
          <Modal
            visible={viewingAge}
            title='Time since last update'
            onOk={() => setViewingAge(false)}
            onCancel={() => setViewingAge(false)}
            width='80rem'
            footer={[
              <Button key='back' onClick={() => setViewingAge(false)}>
                Close
              </Button>,
            ]}
          >
            {teamHistory}
          </Modal>
        </div>

        {orderBy(
          activeEncounter === 'all'
            ? flatten(data.encounters.map((e) => e.items))
            : activeEncounter.items,
          (i) => i.name,
        ).map((item) => {
          const charactersWithWish = item.wishlists.map((w) => w.character_id)
          const trackUpgrades = data.metadata.filter(
            (character) =>
              showTrackUpgrades &&
              !charactersWithWish.includes(character.id) &&
              character.current_tracks[item.equipment_slot] &&
              character.current_tracks[item.equipment_slot] > difficultyIndex &&
              item.eligible_classes.includes(character.class_id) &&
              item.eligible_specs.includes(character.default_spec_id) &&
              (item.equipment_slot !== 'trinket' ||
                character.current_tracks[item.equipment_slot] === 6),
          )

          return (
            <div key={item.blizzard_id} className='item'>
              <h3>
                <a
                  target='_blank'
                  className={item.quality}
                  rel='noreferrer'
                  href={`${baseWowheadUrl}item=${item.blizzard_id}?ilvl=${item.item_level}`}
                >
                  <figure>
                    <img
                      src={`https://render.worldofwarcraft.com/us/icons/36/${
                        item.icon
                      }${item.icon?.includes('.jpg') ? '' : '.jpg'}`}
                    />
                  </figure>
                  {item.name}
                </a>
              </h3>

              <div className='wishes'>
                {item.wishlists.length || trackUpgrades.length ? (
                  <React.Fragment>
                    {item.wishlists
                      .sort(
                        (a, b) =>
                          b[`weighted_${valueDisplay}`] -
                          a[`weighted_${valueDisplay}`],
                      )
                      .map((wishlist) => (
                        <WishlistOverviewWish
                          key={wishlist.character_id}
                          difficultyIndex={difficultyIndex}
                          characterInfo={
                            charactersById[parseInt(wishlist.character_id)]
                          }
                          wishlist={wishlist}
                          valueDisplay={valueDisplay}
                          currentTrack={
                            showTrackUpgrades ? wishlist.equipped_track : null
                          }
                          equippedItemLevels={
                            data.metadata.find(
                              (m) =>
                                parseInt(m.id) ===
                                parseInt(wishlist.character_id),
                            )?.equipped_item_levels || {}
                          }
                          item={item}
                        />
                      ))}
                    {trackUpgrades.map((character) => (
                      <WishlistOverviewWish
                        key={character.id}
                        difficultyIndex={difficultyIndex}
                        characterInfo={charactersById[parseInt(character.id)]}
                        wishlist={character}
                        valueDisplay={valueDisplay}
                        trackOnly={true}
                        currentTrack={
                          character.current_tracks[item.equipment_slot]
                        }
                        equippedItemLevels={character.equipped_item_levels}
                        item={item}
                      />
                    ))}
                  </React.Fragment>
                ) : (
                  <span className='no-upgrade'>Not an upgrade</span>
                )}
              </div>
            </div>
          )
        })}
      </section>
    </div>
  )
}

export default WishlistOverviewTable

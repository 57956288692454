import React from 'react'
import Image from '../components/Image'

import LandingPageContainer from '../components/LandingPageContainer'

class Changelog extends React.Component {
  render() {
    const kind = document.getElementById('subdomain')?.getAttribute('data-kind')

    return kind === 'live' ? (
      <LandingPageContainer>
        <h1 className='logo'>
          <Image name='new-logo-icon' className='icon-only' extension='svg' />
          Changelog
        </h1>
        <p>
          Changes to the War Within spreadsheet will be listed here. You can
          update your spreadsheet by clicking on the large arrow in the settings
          tab.
        </p>
        <h2>Version 1024</h2>
        <ul>
          <li>Increase limit of profession recipes that can be tracked.</li>
          <li>
            Allow customizing visibility in the Professions tab within custom
            ranks in the settings.
          </li>
        </ul>
        <h2>Version 1023</h2>
        <ul>
          <li>
            Fixed Council of Dornogal reputation being swapped with Assembly of
            the Deeps in the single view tab.
          </li>
          <li>
            Replaced the Spreading the Light weekly quest column (it is not
            trackable) with the Call of the Worldsoul weekly.
          </li>
        </ul>
        <h2>Version 1022</h2>
        <ul>
          <li>
            The spreadsheet has been updated to be compatible with The War
            Within! All standard data has been updated to reflect the new
            expansion.
          </li>
          <li>
            The Great Vault now tracks the new World section, including the
            Delves that are completed in that week.
          </li>
          <li>
            Delves completed this week and overall are shown in the overview.
          </li>
          <li>
            Total amount of Restored Coffer Keys obtained is shown in the
            overview.
          </li>
          <li>
            Crests of each type earned by the character, as well as Valorstones,
            is shown in the overview.
          </li>
          <li>
            Heroic dungeons completed in the current week (and overall) is shown
            in the overview.
          </li>
          <li>
            Completion of the new weekly quests is being shown in the overview.
          </li>
        </ul>
        <h2>Version 1021</h2>
        <ul>
          <li>
            Fix issue with raid kills not being displayed properly in the Raids
            tab.
          </li>
        </ul>
        <h2>Version 1020</h2>
        <ul>
          <li>
            Custom ranks can now be configured to hide or show team members on
            individual tabs of the spreadsheet.
          </li>
        </ul>
        <h2>Version 1019</h2>
        <ul>
          <li>All seasonal data in the spreadsheet now reflects season 4.</li>
          <li>
            Added the amount of Bullion purchased items that a character has
            equipped.
          </li>
        </ul>
        <h2>Version 1018</h2>
        <ul>
          <li>
            Added the total amount of upgrade tracks step missing to the
            overview.
          </li>
          <li>
            The Great Vault & Gear tab now has the option to display the upgrade
            level of all equipped gear pieces.
          </li>
        </ul>
        <h2>Version 1017</h2>
        <ul>
          <li>
            Fix issue with incorrect armory links when sorting the overview in a
            specific way.
          </li>
        </ul>
        <h2>Version 1016</h2>
        <ul>
          <li>
            The Loam Niffen and Dream Wardens reputations are now displayed in
            the Single View tab.
          </li>
          <li>The Head enchant is now displayed in the Single View tab.</li>
          <li>
            Fixed an issue with the number displayed for the amount of tier
            items obtained sometimes being incorrect in the summary.
          </li>
          <li>
            Great Vault options of 480 item level are now shown as orange in the
            Great Vault tab, since they are on the Mythic upgrade track.
          </li>
          <li>
            The missing enchants counter on the Summary tab now includes waist
            and head enchants.
          </li>
        </ul>

        <h2>Version 1015</h2>
        <ul>
          <li>All seasonal data in the spreadsheet now reflects season 3.</li>
          <li>
            The Incandescent Essence head enchant is now required in the enchant
            audit.
          </li>
          <li>
            The Dream Wardens reputation is now being tracked in the Overview
            tab.
          </li>
          <li>
            The Fyrakk Mythic only mount is now being tracked in the Overview
            tab.
          </li>
          <li>The race of a character is now displayed in the Overview tab.</li>
        </ul>
        <h2>Version 1014</h2>
        <ul>
          <li>Fix sorting issue of Great Vault score on the Summary tab.</li>
        </ul>
        <h2>Version 1013</h2>
        <ul>
          <li>Really fix sorting issue introduced in version 1011.</li>
        </ul>
        <h2>Version 1012</h2>
        <ul>
          <li>Fix sorting issue introduced in version 1011.</li>
        </ul>
        <h2>Version 1011</h2>
        <ul>
          <li>
            The belt slot is now checked for enchantments in the Overview and
            Single View tabs.
          </li>
          <li>
            Rules for enchant/gem thresholds have been changed slightly. Lenient
            now checks for all best-in-slot enchants/gems if they are an output
            increase or a tertiary stat. Strict checks other less tangible
            benefits (minor Stamina increases like the new belt enchant, or fall
            damage reduction).{' '}
            <strong>
              The note in the Settings tab that explains these thresholds cannot
              be updated automatically.
            </strong>{' '}
            If you are interested in the new text you can take it from a new
            copy.
          </li>
          <li>
            The Great Vault tab is now sorted properly for names with special
            characters. It also now follows the same order that is used on the
            Overview tab.
          </li>
          <li>
            Armory links now point to worldofwarcraft.blizzard.com (which is the
            new official URL).
          </li>
        </ul>
        <h2>Version 1010</h2>
        <ul>
          <li>All seasonal data in the spreadsheet now reflects season 2.</li>
          <li>
            Primordial Stones are now hidden by default in the Overview tab.
          </li>
          <li>
            The Loamm Niffen reputation is now being tracked in the Overview
            tab.
          </li>
          <li>
            The amount of Toys owned by a character can now be seen in the
            Overview tab.
          </li>
        </ul>
        <h2>Version 1009</h2>
        <ul>
          <li>Primordial Stones are now being tracked in the Overview tab.</li>
        </ul>
        <h2>Version 1008</h2>
        <ul>
          <li>
            The Overview tab now displays whether a character has the Well Fed
            Embellishment (Alchemical Flavor Pocket) equipped.
          </li>
        </ul>
        <h2>Version 1007</h2>
        <ul>
          <li>
            Fixed missing owned recipes in the Professions tab for some
            characters with special characters in their name.
          </li>
          <li>
            Corrected the Ranged players being called &apos;Tanks&apos; in the
            Roster tab.
          </li>
        </ul>
        <h2>Version 1006</h2>
        <ul>
          <li>Tier token distribution is now displayed in the Roster tab.</li>
          <li>
            The Illimited Diamond epic gem is now tracked in the Overview tab.
            With the enchant setting &apos;Strict&apos; it has to be a rank 3
            epic gem or it will be flagged. With all the other settings it
            doesn&apos;t matter which rank epic gem a character has equipped.{' '}
            <em>
              The tooltip explaining the enchant settings is outdated but
              unfortunately cannot be updated without making a new copy.
            </em>
          </li>
        </ul>
        <h2>Version 1005</h2>
        <ul>
          <li>
            The Great Vault & Gear tab now has the option to display all the
            Spark of Ingenuity pieces that a character has ever equipped. To see
            it, change the dropdown next to &apos;Gear Item Level&apos;.
          </li>
          <li>
            Solo Shuffle games played and current rating has been added to the
            Overview tab.
          </li>
          <li>Maruuk Centaur Renown now displays the correct maximum of 25.</li>
        </ul>
        <h2>Version 1004</h2>
        <ul>
          <li>
            The Overview tab now displays the Embellishments that characters
            have equipped.
          </li>
          <li>
            A new tab <em>Professions</em> has been added to the spreadsheet,
            displaying information about all rare recipes that characters can
            craft.{' '}
            <strong>
              Due to a limitation in the automatic update process, a new copy is
              required if you want to see this information.
            </strong>
          </li>
        </ul>
        <h2>Version 1003</h2>
        <ul>
          <li>
            The enchants section in the Overview tab now also tracks whether
            sockets have been added to a character&apos;s necklace.
          </li>
          <li>
            The Overview tab now displays how many items characters have
            equipped that need to be crafted with Sparks of Ingenuity.
          </li>
          <li>
            Raid kills this week are now being displayed properly in the Raids
            tab.
          </li>
          <li>
            The background colour of Great Vault raid options has been
            corrected.
          </li>
          <li>Regular Mythic dungeon completions are now hidden by default.</li>
        </ul>
        <h2>Version 1002</h2>
        <ul>
          <li>
            The weekly quests &quot;Aiding the Accord&quot; and &quot;Community
            Feast&quot; are now being tracked on the Overview tab.
          </li>
        </ul>
        <h2>Version 1001</h2>
        <ul>
          <li>
            The spreadsheet has been updated to be compatible with Dragonflight!
          </li>
          <li>Total Mythic+ rating is now displayed in the summary tab.</li>
          <li>All new enchants and tier sets can be tracked.</li>
          <li>
            Regular weekly Mythic dungeon runs (for this first 2 weeks) are
            being tracked in the Overview tab.
          </li>
          <li>
            Renown levels of the new factions are being tracked in the Overview
            tab.
          </li>
          <li>
            Going forward, the spreadsheet can now be updated by clicking the
            update icon in the Settings tab. You should no longer need to make a
            new copy when an update releases (unless there&apos;s a bug in the
            update logic).
          </li>
        </ul>
      </LandingPageContainer>
    ) : kind === 'classic_era' ? (
      <LandingPageContainer>
        <h1 className='logo'>
          <Image name='new-logo-icon' className='icon-only' extension='svg' />
          Changelog
        </h1>
        <p>
          Changes to the Classic Era spreadsheet will be listed here. You can
          update your spreadsheet by clicking on the large arrow in the settings
          tab.
        </p>
        <h2>Version 104</h2>
        <ul>
          <li>
            Custom ranks can now be configured to hide or show team members on
            individual tabs of the spreadsheet.
          </li>
        </ul>
        <h2>Version 103</h2>
        <ul>
          <li>Count classes in the roster regardless of their role.</li>
        </ul>
        <h2>Version 102</h2>
        <ul>
          <li>
            Fix issue with incorrect armory links when sorting the overview in a
            specific way.
          </li>
        </ul>
        <h2>Version 101</h2>
        <ul>
          <li>
            Add conditional formatting to currently equipped gear in the
            Overview tab.
          </li>
        </ul>
      </LandingPageContainer>
    ) : (
      <LandingPageContainer>
        <h1 className='logo'>
          <Image name='new-logo-icon' className='icon-only' extension='svg' />
          Changelog
        </h1>
        <p>
          Changes to the Cataclysm Classic spreadsheet will be listed here. You
          can update your spreadsheet by clicking on the large arrow in the
          settings tab.
        </p>
        <h2>Version 305</h2>
        <ul>
          <li>
            Fix issue with raid kills not being displayed properly in the Raids
            tab.
          </li>
        </ul>
        <h2>Version 304</h2>
        <ul>
          <li>
            Custom ranks can now be configured to hide or show team members on
            individual tabs of the spreadsheet.
          </li>
        </ul>
        <h2>Version 303</h2>
        <ul>
          <li>Updated the spreadsheet to track Cataclysm data.</li>
        </ul>
        <h2>Version 302</h2>
        <ul>
          <li>
            Fix issue with incorrect armory links when sorting the overview in a
            specific way.
          </li>
        </ul>
      </LandingPageContainer>
    )
  }
}

export default Changelog
